var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-collapse",
    {
      staticStyle: { "z-index": "10" },
      attrs: { bordered: false },
      scopedSlots: _vm._u([
        {
          key: "expandIcon",
          fn: function(props) {
            return [
              _c("a-icon", {
                attrs: { type: "caret-right", rotate: props.isActive ? 90 : 0 }
              })
            ]
          }
        }
      ])
    },
    [
      _c(
        "a-collapse-panel",
        {
          key: "1",
          style: {
            background: "#f7f7f7",
            "border-radius": "4px",
            "margin-bottom": "12px",
            border: 0,
            overflow: "hidden"
          },
          attrs: { header: _vm.$t("Advanced information") }
        },
        [
          _c(
            "a-card",
            {
              staticClass: "my-sm",
              attrs: {
                headStyle: _vm.cardHeadStyle,
                bodyStyle: { padding: "12px 24px" }
              }
            },
            [
              _c("template", { slot: "title" }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("Attached photo")))]),
                _c("span", { staticClass: "desc" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "(If you set an image, it will be displayed as a thumbnail image of Reading Homepage)"
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("span", [_vm._v(_vm._s(_vm.$t("Attached photo")) + ":")]),
              _c("upload-photo", {
                attrs: {
                  url: _vm.thumbnail.url,
                  "folder-name-s3": _vm.folderNameS3
                },
                on: {
                  upload: function(url) {
                    _vm.$emit(
                      "changeThumbnail",
                      Object.assign({}, _vm.thumbnail, { url: url })
                    )
                  },
                  delete: function() {
                    _vm.$emit("changeThumbnail", { url: "", alt: "" })
                  }
                }
              }),
              _c("br"),
              _c("span", [_vm._v(_vm._s(_vm.$t("Alt texts")) + ":")]),
              _c(
                "a-form",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "a-form-item",
                    {
                      staticStyle: { margin: "0" },
                      attrs: {
                        "validate-status": _vm.validateThumbnailALT,
                        help:
                          _vm.validateThumbnailALT === "error" &&
                          _vm.$t("Photo alt text is required")
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          disabled: !_vm.thumbnail.url,
                          value: _vm.thumbnail.alt
                        },
                        on: {
                          change: function(ref) {
                            var target = ref.target

                            _vm.$emit(
                              "changeThumbnail",
                              Object.assign({}, _vm.thumbnail, {
                                alt: target.value
                              })
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _c(
            "a-card",
            {
              staticClass: "my-sm",
              attrs: {
                headStyle: _vm.cardHeadStyle,
                bodyStyle: { padding: "12px 24px" }
              }
            },
            [
              _c("template", { slot: "title" }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("Attached URL")))]),
                _c("span", { staticClass: "desc" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "(If you set up a link, this link will be displayed as「More details」in the Uni-Voice Mobile app.)"
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c("a-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  value: _vm.linkURL,
                  placeholder: _vm.$t("(Optional)")
                },
                on: { change: _vm.changeOriginalLink }
              })
            ],
            2
          ),
          _c(
            "a-card",
            {
              staticClass: "my-sm",
              attrs: {
                headStyle: _vm.cardHeadStyle,
                bodyStyle: { padding: "12px 24px" }
              }
            },
            [
              _c("template", { slot: "title" }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("JAN Codes")))]),
                _c("span", { staticClass: "desc" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "(If you register a JAN code as a voice code, the Uni-Voice Mobile App will scan the JAN code then display and read out the contents of this voice code)"
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _vm._l(_vm.janCodes, function(janCode) {
                return _c(
                  "a-row",
                  {
                    key: janCode.id,
                    staticClass: "my-sm",
                    attrs: { type: "flex", align: "middle" }
                  },
                  [
                    _c(
                      "a-col",
                      { attrs: { xs: 22, lg: 12 } },
                      [
                        _c("a-input", {
                          attrs: {
                            placeholder: _vm.$t("(Optional)"),
                            "enter-button": "OK"
                          },
                          on: {
                            search: function($event) {
                              return _vm.changeJanCode(janCode)
                            }
                          },
                          model: {
                            value: janCode.value,
                            callback: function($$v) {
                              _vm.$set(janCode, "value", $$v)
                            },
                            expression: "janCode.value"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 2 } },
                      [
                        _c("a-icon", {
                          staticClass: "trigger",
                          staticStyle: {
                            color: "rgb(255 68 68)",
                            "line-height": "30px",
                            padding: "0 6px"
                          },
                          attrs: { type: "close-circle" },
                          on: {
                            click: function($event) {
                              return _vm.removeJanCode(janCode)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c("a-icon", {
                staticClass: "trigger",
                staticStyle: {
                  color: "#1890FF",
                  "line-height": "46px",
                  padding: "0 15px"
                },
                attrs: { type: "plus-circle" },
                on: { click: _vm.addJanCode }
              })
            ],
            2
          ),
          _c(
            "a-card",
            {
              staticClass: "my-sm",
              attrs: {
                headStyle: _vm.cardHeadStyle,
                bodyStyle: { padding: "12px 24px" }
              }
            },
            [
              _c("template", { slot: "title" }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("Tags")))]),
                _c("span", { staticClass: "desc" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "(Tags are used to classify codes. It can be used for several purposes such as subcategories, search, etc.)"
                        )
                      ) +
                      " "
                  )
                ])
              ]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    mode: "tags",
                    value: _vm.tags,
                    placeholder: _vm.$t("Please enter a tag")
                  },
                  on: { change: _vm.changeTags }
                },
                _vm._l(_vm.oldTags, function(tag) {
                  return _c(
                    "a-select-option",
                    { key: tag, attrs: { value: tag } },
                    [_vm._v(" " + _vm._s(tag) + " ")]
                  )
                }),
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }