//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  props: {
    codeDictionary: { type: Array, default: () => [] },
    projectDictionary: { type: Array, default: () => ([]) },
    pageDictionary: { type: Array, default: () => ([]) },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('dictionary', ['dictionaryTree']),
    dictionaryTreeData() {
      if (this.usedDictionary?.length < 5) {
        return this.dictionaryTree.map(lev1 => ({ ...lev1, title: this.$t(lev1.title) }))
      }
      const newDictionaryTree = this.dictionaryTree.map(lev1 => {
        const children = lev1.children.map(dic => ({
          ...dic,
          disabled: !this.usedDictionary.includes(dic.value)
        }))
        return {
          title: this.$t(lev1.title),
          children
        }
      })
      return newDictionaryTree
    },
    usedDictionary() {
      return (this.codeDictionary?.length > 0
        ? this.codeDictionary
        : this.pageDictionary?.length > 0
          ? this.pageDictionary
          : this.projectDictionary) || []
    },
    isUsedProjectDictionaryAsDefault() {
      const projectDicString = JSON.stringify([...(this.projectDictionary || [])].sort())
      const usedDicString = JSON.stringify([...(this.usedDictionary || [])].sort())
      return this.projectDictionary?.length > 0 && projectDicString == usedDicString
    },
    isUsedPageDictionaryAsDefault() {
      const pageDicString = JSON.stringify([...(this.pageDictionary || [])].sort())
      const usedDicString = JSON.stringify([...(this.usedDictionary || [])].sort())
      return this.pageDictionary?.length > 0 && pageDicString == usedDicString
    }
  },
  methods: {
    handleChangeDictionary(value) {
      this.$emit('changeDictionary', value)
    },
    handleOpenDictionary(dicName) {
      this.$router.push(`/settings/speech-setting?dicName=${dicName}`);
    },
    handleTreeSelectListener() {
      // Handle event TreeSelect listener
      const treeSelectElement = this.$refs?.treeSelectRef?.$el;
      treeSelectElement?.addEventListener('click', (event) => {
        if (event?.target?.className === 'ant-select-selection__choice__content') {
          const dicName = event?.target?.textContent
          console.log(dicName)
          this.handleOpenDictionary(dicName)
        }
      });
    }
  },
  mounted() {
    this.handleTreeSelectListener()
  },
  beforeDestroy() {
    // Handle remove event TreeSelect listener
    const treeSelectElement = this.$refs?.treeSelectRef?.$el;
    treeSelectElement?.removeEventListener('click', null);
  },
};
