var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "code-editor" },
    [
      _c("editor-content", { attrs: { editor: _vm.editor } }),
      _c(
        "a-modal",
        {
          staticClass: "code-editor-modal",
          attrs: { visible: !!_vm.selectedText, closable: false },
          on: {
            cancel: function($event) {
              _vm.selectedText = ""
            }
          }
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("Add XXX to dictionary", { key: _vm.selectedText })
                ) +
                " "
            )
          ]),
          _c(
            "a-form",
            { attrs: { form: _vm.form }, on: { submit: _vm.handleSubmit } },
            [
              _c(
                "a-form-item",
                {
                  staticStyle: { "margin-bottom": "12px" },
                  attrs: { label: _vm.$t("Language") }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["language", _vm.formItemOptions.language],
                        expression: "['language', formItemOptions.language]"
                      }
                    ],
                    attrs: { disabled: "" }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { staticStyle: { "margin-bottom": "12px" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: {
                        value: _vm.selectedDictionaryOption,
                        "default-value": "exist"
                      },
                      on: { change: _vm.handleChangeDictionaryOption }
                    },
                    [
                      _c("a-radio", { attrs: { value: "exist" } }, [
                        _vm._v(_vm._s(_vm.$t("Add to existing dictionary")))
                      ]),
                      _c("a-radio", { attrs: { value: "new" } }, [
                        _vm._v(_vm._s(_vm.$t("Create new dictionary")))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  staticStyle: { "margin-bottom": "12px" },
                  attrs: { label: _vm.$t("Dictionary name") }
                },
                [
                  _vm.selectedDictionaryOption === "exist"
                    ? _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: ["name", _vm.formItemOptions.name],
                              expression: "['name', formItemOptions.name]"
                            }
                          ]
                        },
                        _vm._l(_vm.dictionaryOptions, function(dic) {
                          return _c(
                            "a-select-option",
                            { key: dic.name, attrs: { value: dic.name } },
                            [_vm._v(" " + _vm._s(dic.name) + " ")]
                          )
                        }),
                        1
                      )
                    : _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["name", _vm.formItemOptions.name],
                            expression: "['name', formItemOptions.name]"
                          }
                        ]
                      })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  staticStyle: { "margin-bottom": "12px" },
                  attrs: { label: _vm.$t("Word") }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["word", _vm.formItemOptions.word],
                        expression: "['word', formItemOptions.word]"
                      }
                    ]
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  staticStyle: { "margin-bottom": "12px" },
                  attrs: { label: _vm.$t("Reading") }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex", width: "100%" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["reading", _vm.formItemOptions.reading],
                            expression: "['reading', formItemOptions.reading]"
                          }
                        ]
                      }),
                      _c(
                        "a-dropdown-button",
                        {
                          staticStyle: {
                            "margin-left": "4px",
                            "line-height": "normal"
                          },
                          attrs: {
                            trigger: ["click"],
                            placement: "bottomLeft",
                            disabled: !_vm.form.getFieldValue("reading")
                          },
                          on: {
                            click: function() {
                              return _vm.isPlaying
                                ? _vm.handlePause()
                                : _vm.handlePlay()
                            }
                          }
                        },
                        [
                          _c("a-icon", {
                            attrs: {
                              type: _vm.isPlaying
                                ? "pause-circle"
                                : "play-circle"
                            }
                          }),
                          _vm._v(" " + _vm._s(_vm.$t(_vm.gender)) + " "),
                          _c(
                            "a-menu",
                            {
                              attrs: { slot: "overlay" },
                              on: { click: _vm.handleChangeGender },
                              slot: "overlay"
                            },
                            [
                              _c(
                                "a-menu-item",
                                { key: "female" },
                                [
                                  _c("a-icon", { attrs: { type: "user" } }),
                                  _vm._v(" " + _vm._s(_vm.$t("Female")) + " ")
                                ],
                                1
                              ),
                              _c(
                                "a-menu-item",
                                { key: "male" },
                                [
                                  _c("a-icon", { attrs: { type: "user" } }),
                                  _vm._v(" " + _vm._s(_vm.$t("Male")) + " ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("a-icon", {
                            attrs: { slot: "icon", type: "setting" },
                            slot: "icon"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "a-form-item",
                {
                  staticStyle: { "margin-bottom": "12px" },
                  attrs: { label: _vm.$t("Note") }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["note", _vm.formItemOptions.note],
                        expression: "['note', formItemOptions.note]"
                      }
                    ]
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                { on: { click: _vm.handleCloseDictionaryCreationModal } },
                [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: {
                    "html-type": "submit",
                    type: "primary",
                    loading: _vm.loading
                  },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }