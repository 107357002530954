import { render, staticRenderFns } from "./DictionaryInfo.vue?vue&type=template&id=5a647d27&scoped=true&"
import script from "./DictionaryInfo.vue?vue&type=script&lang=js&"
export * from "./DictionaryInfo.vue?vue&type=script&lang=js&"
import style0 from "./DictionaryInfo.vue?vue&type=style&index=0&id=5a647d27&lang=scss&scoped=true&"
import style1 from "./DictionaryInfo.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a647d27",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4073529217/src/univoice-company-console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a647d27')) {
      api.createRecord('5a647d27', component.options)
    } else {
      api.reload('5a647d27', component.options)
    }
    module.hot.accept("./DictionaryInfo.vue?vue&type=template&id=5a647d27&scoped=true&", function () {
      api.rerender('5a647d27', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/page/create-code/voice-code/components/DictionaryInfo.vue"
export default component.exports