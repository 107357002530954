//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import SsmlEditor from '@/components/SSMLEditor';
import CodeEditor from '@/components/common/CodeEditor.vue'

export default {
  components: {
    SsmlEditor,
    CodeEditor,
  },
  props: {
    code: { type: Object, required: true, default: () => ({}) },
    status: { type: String, required: true, default: '' },
    langOptions: { type: Array, required: true, default: () => [] },
    dictionary: { type: Array, default: () => ([]) },
    engine: { type: String, default: "standard" }
  },
  data() {
    return {
      ssmlEditor: false,
      reflectText: false,
      voiceGender: ['female']
    };
  },
  computed: {
    ...mapGetters(['currentLanguage']),
    validateStatusLang() {
      return this.code.lang === '' ? 'error' : 'success';
    },
    validateStatusContent() {
      return this.code.text === '' ? 'error' : 'success';
    },
    errorMessageContent() {
      if (!this.code.text) {
        return this.$t('Content is required');
      }
      return '';
    },
    genderLabel() {
      return this.voiceGender[0] === 'female' ? this.$t('Female') : this.$t('Male');
    }
  },
  methods: {
    deleteCode() {
      this.$emit('deleteCode', this.code.id);
    },
    changeLanguage(value) {
      this.$emit('changeLang', { lang: value, id: this.code?.id });
    },
    changeContent(value) {
      this.$emit('changeCodeContent', { id: this.code.id, content: value });
    },
    changeSSMLContent(value) {
      this.$emit('changeSSMLContent', { id: this.code.id, content: value });
      this.reflectText = false;
    },
    handlePlaySpeech() {
      const { id, text, lang, ssml } = this.code;
      let type = this.ssmlEditor ? 'ssml' : 'text';
      this.$emit('onPlaySpeech', { id, text, lang, ssml, type, gender: this.voiceGender });
    },
    handlePauseSpeech() {
      this.$emit('onPauseSpeech', { id: this.code.id });
    },
    openPreviewCodeContent() {
      this.$emit('previewCodeContent', { id: this.code.id });
    },
    changeSSMLAsSpeech(checked) {
      this.$emit('enableSSML', { id: this.code.id, is_enable: checked });
      if (checked) {
        this.reflectText = true;
        this.$emit('changeSSMLContent', {
          id: this.code.id,
          content: `<speak>${this.code.text}</speak>`
        });
      }
    },
    onChangeTabEditor(key) {
      this.ssmlEditor = key === '2';
    },
    catchSSMLError(error) {
      let id = this.code.id;
      this.$emit('catchSSMLError', id, error);
    },
    handleChangeGender(event) {
      this.voiceGender = [event.key];
    }
  }
};
