//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import UploadPhoto from '@/components/spec/UploadPhoto.vue';

export default {
  props: {
    janCodes: { type: Array, default: () => [] },
    thumbnail: { type: Object, default: () => ({ url: '', alt: '' }) },
    linkURL: { type: String, default: '' },
    tags: { type: Array, default: () => [] },
  },
  components: { UploadPhoto },
  data() {
    return {
      loading: false,
      oldTags: []
    };
  },
  watch: {
    tags: {
      handler(tags) {
        if (!this.oldTags.length) this.oldTags = tags;
      }
    }
  },

  computed: {
    cardHeadStyle() {
      return { fontSize: '14px' };
    },
    validateThumbnailALT() {
      const { url, alt } = this.thumbnail;
      return url && !alt ? 'error' : 'success';
    },
    folderNameS3() {
      return `${process.env.VUE_APP_S3_CODE_THUMBNAIL}/${this.$route.params.pageId}`;
    }
  },

  methods: {
    changeOriginalLink(event) {
      this.$emit('changeOriginalLink', event);
    },
    changeJanCode(janCode) {
      this.$emit('changeJanCode', janCode);
    },
    addJanCode() {
      this.$emit('addJanCode');
    },
    removeJanCode(janCode) {
      this.$emit('removeJanCode', janCode);
    },
    changeTags(tags) {
      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i];
        if (this.oldTags.indexOf(tag) === -1) {
          this.oldTags.push(tag);
        }
      }
      this.$emit('changeTags', tags);
    },
  }
};
