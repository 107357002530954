var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-collapse",
    {
      staticClass: "dictionary-info",
      staticStyle: { "z-index": "10" },
      attrs: { bordered: false },
      scopedSlots: _vm._u([
        {
          key: "expandIcon",
          fn: function(props) {
            return [
              _c("a-icon", {
                attrs: { type: "caret-right", rotate: props.isActive ? 90 : 0 }
              })
            ]
          }
        }
      ])
    },
    [
      _c(
        "a-collapse-panel",
        {
          key: "1",
          style: {
            background: "#f7f7f7",
            "border-radius": "4px",
            "margin-bottom": "12px",
            border: 0,
            overflow: "hidden"
          },
          attrs: { header: _vm.$t("Dictionary"), "force-render": true }
        },
        [
          _c(
            "a-card",
            {
              staticClass: "my-sm",
              attrs: {
                headStyle: { fontSize: "14px" },
                bodyStyle: { padding: "12px 24px" }
              }
            },
            [
              _c("template", { slot: "title" }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("Dictionary")))]),
                _c("span", { staticClass: "desc" }, [
                  _vm._v(
                    " (" +
                      _vm._s(
                        _vm.$t(
                          "Choosing dictionary will enable customize the pronunciation of words when reading content. Maximum 5 dictionaries can be selected."
                        )
                      ) +
                      ") "
                  )
                ])
              ]),
              _c("a-tree-select", {
                ref: "treeSelectRef",
                style: {
                  "--dynamic-color": _vm.isUsedProjectDictionaryAsDefault
                    ? "#eb2f96"
                    : _vm.isUsedPageDictionaryAsDefault
                    ? "#1890ff"
                    : "#52c41a",
                  "--dynamic-bg": _vm.isUsedProjectDictionaryAsDefault
                    ? "#fff0f6"
                    : _vm.isUsedPageDictionaryAsDefault
                    ? "#e6f7ff"
                    : "#f6ffed",
                  width: "100%"
                },
                attrs: {
                  value: _vm.usedDictionary,
                  "tree-data": _vm.dictionaryTreeData,
                  "tree-checkable": "",
                  "search-placeholder": _vm.$t("Please select")
                },
                on: { change: _vm.handleChangeDictionary }
              }),
              _vm.isUsedProjectDictionaryAsDefault ||
              _vm.isUsedPageDictionaryAsDefault
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-bottom": "4px",
                        "line-height": "normal",
                        color: "#be853a",
                        "font-size": "13px"
                      }
                    },
                    [
                      _vm._v(
                        " * " +
                          _vm._s(
                            _vm.$t(
                              "Project dictionary or Page dictionary is set already but you are trying to change it, Code dictionary is used as higher priority"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }